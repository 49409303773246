/* omUIapi css - Version 1.06 September 21, 2022 */
:root {
  --main-bottom: 0px; /* if Footer=true - set to 40 */
  --main-left: 182px; /* 11.5% Sidebar */
  --main-width: 90%; /* Note - the side panel is fixed - so there may be some missing percent of page width*/
  --header-height: 55px; /* for FullHeader 104  for slimHeader 50 */
  --header-msg-height: 50px;
  --header-ht-top: 26px; /* distance from top to bottom div (height of top div */
  --prompt-left: 130px;
  --prompt-narrow-left: 90px;
  --back-color: #f5fafa;
  --back-color-ro: #DEE8E9;
  --text-color: #026dc4;
  --box-color: #c1f7f2; /*#a8f3fbff;*/
  --box-color-red: #ffdd99; /*#a8f3fbff;*/
  --button-color: #1b347e;
  --button-back: #d5ddf6;
  --attn-color: #009900;
  --scan-height: 40px;
  --option-height: 400px;
  --prompt-left: 130px;
  --prompt-narrow-left: 90px;
  --scan-height: 40px;
  --detail-heightS: 400px;
  --menu-height: 330px;
  --screen-width: 700px;
  --screen-height: 700px;
  --okay-color: #248f24;
  --attn-color: #009900;
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --InputTextColor: #026dc4;
  --InputBGColor: #f5fafa;
  --InputBorderColor: #c1f7f2;
  --InputFontType: 'Verdana';
  --InputFontSize: '14px';
  --ButtonTextColor: #1b347e;
  --ButtonBGColor: #d5ddf6;
  --ButtonFontType: 'Verdana';
  --ButtonFontSize: '14px';
  --HeadingTextColor: #f0f0f0;
  --HeadingFontType: 'Verdana';
  --HeadingFontSize: '14px';
  --ItemNameColor: #f0f0f0;
  --ItemNameFontType: 'Verdana';
  --ItemNameFontSize: '14px';
  --ItemNameFontWht: '14px';
  --ItemDescColor: #f0f0f0;
  --ItemDescFontType: 'Verdana';
  --ItemDescFontSize: '14px';
  --ItemDescFontWht: '14px';
  --ListBGColor: #f0f0f0;
  --ListFontType: 'Verdana';
  --ListFontSize: '14px';
  --ListHeadTextColor: #f0f0f0;
  --ListHeadBGColor: #f0f0f0;  
  --HeadFontType: 'Verdana';
  --HeadFontSize: '14px';
  --ListSectionTextColor: #f0f0f0;
  --ListSectionBGColor: #f0f0f0;
  --SectionFontType: 'Verdana';
  --SectionFontSize: '14px';   
  --PromptColor: #f0f0f0;
  --PromptFontType: 'Verdana';
  --PromptFontSize: '14px';
} * {
  margin: 0px;
  padding: 0px;  
} body {
  font: 12px Arial, Helvetica, sans-serif; /* Myriad Pro */
  color: #a7a6a6;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}
  /*------------- Header and Footer css ---------------------------------------------------------------------------*/
  .clear {
  clear: both;
  margin: 0px;
  padding: 0px;
} a {
  color: #fff;
  text-decoration: underline;
} a:hover {
    text-decoration: none;
  } menuhref {
  padding: 0;
  margin: 0px 0px 0px 1px;
} #Search {
  border-radius: 5px;
  position: absolute;
  top: 2px;
  right: 34px;
  height: 24px;
  width: 160px;
  resize: none;
} .searchBtnImg {
  position: absolute;
  margin: 0;
  top: 3px;
  right: 10px;
  width: 21px;
  resize: both;
  height: 23px;
} .searchClearImage {
  position: absolute;
  left: 1px;
  top: 3px;
  width: 14px;
  resize: both;
  height: 19px;
  background-color: white;
} .framePic {
  position: relative;
  top: 0px;
  width: 380px;
  height: 290px;
  border-radius: 10px;
  border: 3px solid #ccc;
  background: #eee;
  margin: 0;
  padding: 2px 2px 2px 2px;
} .avatarFramePic {
  width: 370px;
  height: 280px;
  background-color: white;
  border-radius: 10px;
} .framePicUpload {
  width: 374px;
  height: 55px;
  background: #eee;
  margin: 0;
  padding: 2px 2px 2px 4px;
} .frameSecondPic {
  border: none; /*solid 3px red;*/
  position: absolute;
  top: 520px;
  bottom: 1px;
  left: 10px;
  right: 10px;
  background-color: white;
} .framePicLong {
  position: relative;
  top: 0px;
  width: 380px;
  height: 610px;
  border-radius: 10px;
  border: 3px solid #ccc;
  background: #eee;
  margin: 0;
  padding: 2px 2px 2px 2px;
} .avatarLongFrame {
  width: 370px;
  height: 600px;
  border-radius: 10px;
  background-color: white;
} .framePromoPicUpload {
  width: 404px;
  height: 55px;
  background: #eee;
  margin: 0;
  padding: 2px 2px 2px 4px;
} .framePromoPic {
  width: 410px;
  height: 210px;
  border-radius: 10px;
  border: 3px solid #ccc;
  background: #eee;
  margin: 0;
  padding: 2px 2px 2px 2px;
} .avatarPromoPic {
  width: 400px;
  height: 200px;
  border-radius: 10px;
  background-color: white;
} .frameProfile {
  width: 300px;
  height: 300px;
  border-radius: 10px;
  border: 3px solid #ccc;
  background: #eee;
  margin: 0;
  padding: 2px 2px 2px 2px;
} #Messages {
  border: none;
  border-radius: 5px;
  position: absolute;
  top: 2px;
  right: 1px;
  left: 2px;
  width: 98%;
  bottom: 2px;
  resize: none;
  background-color: gray;
} .messageImage { /* clear Image x for Message Box */
  position: absolute;
  right: 1px;
  top: 2px;
  width: 13px;
  resize: both;
  height: 13px;
} .showMessageImage {
  position: absolute;
  right: -5px;
  top: -3px;
  width: 20px;
  resize: both;
  height: 20px;
} .popupImage { /* message popup Image x for Message Box */
  position: absolute;
  right: 3px;
  top: 32px;
  width: 13px;
  resize: both;
  height: 13px;
} popup-content {
  padding: 5px;
  color: #787878;
  background-color: #fcf7f8;
  border: 1px solid rgba(0,0,0,.05);
} #contactBox {
  border: solid 2px #0073e6;
  border-radius: 5px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 90%;
  height: 300px;
  padding: 4px 3px 4px 5px;
  margin-top: 0px;
  color: var(--text-color);
  font-size: 18px;
  resize: none;
} #messageBox { /* see #headerTopMid */
  border: solid 2px #0073e6;
  border-radius: 5px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
  top: 3px;
  bottom: 2px;
/* max-width: 750px;*/
  background-color: white;
  text-decoration: none;
  text-align: left;
  color: black;
  line-height: 13px;
  font-family: Arial;
  font-size: 12px;
  scrollbar-face-color: #367CD2;
  scrollbar-shadow-color: #FFFFFF;
  scrollbar-highlight-color: #FFFFFF;
  scrollbar-3dlight-color: #FFFFFF;
  scrollbar-darkshadow-color: #FFFFFF;
  scrollbar-track-color: #FFFFFF;
  scrollbar-arrow-color: #FFFFFF;
} #messageBoxPU {
  border: solid 2px #0073e6;
  border-radius: 5px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 98%;
  width: 99%;
  top: 3px;
  right: 3px;
  left: 3px;
  bottom: 2px;
  max-width: 700px;
  background-color: white;
  text-decoration: none;
  text-align: left;
  color: black;
  line-height: 13px;
  font-family: Arial;
  font-size: 12px;
  scrollbar-face-color: #367CD2;
  scrollbar-shadow-color: #FFFFFF;
  scrollbar-highlight-color: #FFFFFF;
  scrollbar-3dlight-color: #FFFFFF;
  scrollbar-darkshadow-color: #FFFFFF;
  scrollbar-track-color: #FFFFFF;
  scrollbar-arrow-color: #FFFFFF;
} #notifyBoxPU {
  /*border: solid 2px red;#0073e6; */
  border-radius: 5px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 99%;
  top: 2px;
  right: 1px;
  left: 1px;
  max-width: 500px;
  bottom: 2px;
  background-color: white;
  text-decoration: none;
  text-align: left;
  color: black;
  line-height: 13px;
  font-family: Arial;
  font-size: 14px;
  scrollbar-face-color: #367CD2;
  scrollbar-shadow-color: #FFFFFF;
  scrollbar-highlight-color: #FFFFFF;
  scrollbar-3dlight-color: #FFFFFF;
  scrollbar-darkshadow-color: #FFFFFF;
  scrollbar-track-color: #FFFFFF;
  scrollbar-arrow-color: #FFFFFF;
} #profilePopup {
  border: solid 2px #0073e6;
  border-radius: 5px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: visible;
  height: 180px;
  top: 2px;
  right: 1px;
  left: 2px;
  width: 100%;
  max-width: 500px;
  bottom: 2px;
  background-color: white;
  text-decoration: none;
  text-align: left;
  color: wheat;
  line-height: 13px;
  font-family: Arial;
  font-size: 12px;
  scrollbar-face-color: #367CD2;
  scrollbar-shadow-color: #FFFFFF;
  scrollbar-highlight-color: #FFFFFF;
  scrollbar-3dlight-color: #FFFFFF;
  scrollbar-darkshadow-color: #FFFFFF;
  scrollbar-track-color: #FFFFFF;
  scrollbar-arrow-color: #FFFFFF;
} #detailPopup { /* see popupDetail */
  border: solid 2px #0073e6;
  border-radius: 8px;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 500px;
  top: 1px;
  right: 1px;
  left: 1px;
  width: 100%;
  bottom: 2px;
  background-color: white;
  text-decoration: none;
  text-align: left;
  color: wheat;
  line-height: 13px;
  font-family: Arial;
  font-size: 12px;
  scrollbar-face-color: #367CD2;
  scrollbar-shadow-color: #FFFFFF;
  scrollbar-highlight-color: #FFFFFF;
  scrollbar-3dlight-color: #FFFFFF;
  scrollbar-darkshadow-color: #FFFFFF;
  scrollbar-track-color: #FFFFFF;
  scrollbar-arrow-color: #FFFFFF;
} #optionPopup { /* see popupOptions */
  border: solid 2px #0073e6;
  border-radius: 8px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: visible;
  top: 1px;
  right: 1px;
  left: 1px;
  height: 680px;
  width: 100%;
  bottom: 1px;
  background-color: white;
  text-decoration: none;
  text-align: left;
  color: wheat;
  line-height: 13px;
  font-family: Arial;
  font-size: 12px;
  scrollbar-face-color: #367CD2;
  scrollbar-shadow-color: #FFFFFF;
  scrollbar-highlight-color: #FFFFFF;
  scrollbar-3dlight-color: #FFFFFF;
  scrollbar-darkshadow-color: #FFFFFF;
  scrollbar-track-color: #FFFFFF;
  scrollbar-arrow-color: #FFFFFF;
} .popupTitle {
  font-size: medium;
  right: 0;
  color: darkblue;
  padding: 2px;
} .errorMessage {
  color: red; 
  font-size: 13px;
} .infoMessage {
  color: blue;
  font-size: 18px;
} .detailMessage {
  color: darkgray;
  font-size: 14px;
} #PageTitle {
  position: absolute;
  top: 2px;
  right: 2px;
  left: 2px;
  width: 98%;
  bottom: 2px;
  resize: none;
} btnImage.resize {
  transform: scale(0.5, 0.5);
  width: 20px;
  height: 20vh;
} menuImage {
  height: 42px;
  float: right;
  margin: 0px;
  display: block;
  background: url(images/Info2.png) left top no-repeat;
}
  /* Row and Col */
  .row {
  display: flex;
  width: 100%
} .row::after {
  /* display: block;*/
  clear: both;
  content: "";
} .col-1 {
  width: 8.33%;
} .col-2 {
  width: 16.66%;
} .col-3 {
  width: 25%;
} .col-4 {
  width: 33.33%;
} .col-5 {
  width: 41.66%;
} .col-6 {
  width: 50%;
} .col-7 {
  width: 58.33%;
} .col-8 {
  width: 66.66%;
} .col-9 {
  width: 75%;
} .col-10 {
  width: 83.33%;
} .col-11 {
  width: 91.66%;
} .col-12 {
  width: 100%;
} .col {
  width: 25%;
} * {
  box-sizing: border-box;
} [class*="col-"] {
  border: 2px solid grey;
  padding: 15px;
  float: left;
} .popupQRCode {
  padding: 0px;
  margin: 0px;
  color: #787878;
  background-color: #fcf7f8;
  border: solid 2px #0073e6;
  border-radius: 5px;
  border-style: groove;
  position: absolute;
  left: 0;
  width: 210px;
  height: 300px;
  line-height: 13px;
  font-family: Arial;
  font-size: 12px;
} .popupHelp {
  padding: 0px;
  margin: 0px;
  color: #787878;
  background-color: #fcf7f8;
  border-radius: 5px;
  border-style: groove;
  position: absolute;
  left: 0;
  width: 500px;
  height: 900px;
  line-height: 13px;
  font-family: Arial;
  font-size: 12px;
} .popupHelpDetail {
  padding: 5px 0px 3px 5px;
  margin: 0px;
  color: #787878;
  background-color: #fcf7f8;
  width: 500px;
  height: 600px;
  border: solid 3px #0073e6;
  border-radius: 10px;
  border-style: groove;
} .popupMessageDetail {
  padding: 5px 0px 3px 5px;
  margin: 0px;
  color: #787878;
  background-color: #fcf7f8;
  width: 400px;
  height: 230px;
  border: solid 3px #0073e6;
  border-radius: 10px;
  border-style: groove;
} .headerScan {
  position: absolute;
  left: 5%;
  top: 5px;
  height: 56px; /*82px;*/
  width: 90%;
  /*border: 1px solid green; */
}
  /*------------- Main Page ------------------------------------------*/
  .pageMain {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  background-color: white;
} #mainCntr {
  position: absolute;
  top: var(--header-height);
  bottom: var(--main-bottom);
  left: var(--main-left);
  width: var(--main-width);
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  border: none; /*5px solid red;*/
}
  /*------------- HEADER -------------*/
  #headerContainer {
  border: none;
  position: relative;
  left: var(--main-left);
  right: 0;
  top: 0;
  width: var(--main-width);
  margin: 0;
  padding: 0;
  height: var(--header-height);
  background-color: white; /*#1B2026;*/
  /*resize: both;*/
  /*background: url(images/header_bg.jpg) top left no-repeat; */
} #headerLoginContainer {
  border: none;
  position: relative;
  left: var(--main-left);
  right: 0;
  top: 0;
  width: var(--main-width);
  margin: 0;
  padding: 0;
  height: var(--header-height);
  background-color: white; /*#1B2026;*/
  /*resize: both;*/
  /*background: url(images/header_bg.jpg) top left no-repeat; */
} .logonBottom {
  border: 1px solid red; /**/
  position: absolute;
  bottom: 5px;
  height: 26px;
  width: 150px;
  left: 80px;
  font-size: 18px;
  font-weight: bolder;
} #headerLogo {
  border: none;
  position: relative;
  left: 10px;
  top: 0px;
  display: block;
  width: 100%;
  height: 70px;
  /*background: url(images/LogoOllysGrey.jpg) top left no-repeat;*/
  background: url(images/ScanPayLogo.png) top left no-repeat;
  background-size: 130px 70px; /* NOTE - Logo Size */
} .buttonDivLeft {
  border: none; /*2px solid green; */
  position: absolute;
  left: 20px;
  width: 50px;
  height: 28px;
  bottom: 0px;
} .buttonDivRight {
  border: none; /* 1px solid yellow; 2px solid red;  */
  position: absolute;
  width: 50px;
  height: 28px;
  bottom: 0px;
  right: 20px;
  float: right;
} #headerBotLeft h2 {
  border: none; /*1px solid orange;  */
  margin-top: 2px;
  top: 0px;
  padding-left: 80px; /* left from headerBotLeft edge */
  font-size: 24px;
  color: black;
  font-weight: normal;
  float: left;
} #headerTopLeft h2 {
  border: none; /*1px solid orange;  */
  margin-top: 2px;
  top: 0px;
  padding-left: 260px; /* left from headerBotLeft edge */
  font-size: 24px;
  color: black;
  font-weight: normal;
  float: left;
} #headerTopLeft {
  border: none; /*5px solid red;*/
  position: absolute;
  left: 13px;
  top: 0px;
  width: 40px;
  height: 30px;
} .showHelpImage {
  position: absolute;
  top: -1px;
  width: 30px;
  resize: both;
  height: 30px;
} .showFieldHelp {
  position: absolute;
  color: var(--blue);
  left: -14px;
  width: 18px;
  resize: both;
  height: 18px;
  padding-top: 2px;
} .showActionHelp {
  position: absolute;
  color: var(--blue);
  left: 3px;
  width: 32px;
  resize: both;
  height: 32px;
  padding-top: 0px;
} .actionName {
  position: absolute;
  color: var(--blue);
  left: 38px;
  font-size: 22px;
  font-weight: bold;
} #headerTopLeftIcon {
  border: none; /*5px solid red;*/
  position: absolute;
  left: 340px;
  top: 0px;
  width: 10px;
  height: 10px;
} #headerTopMidIcon { 
  /*border: 1px solid red;*/
  position: absolute;
  /*left: 1000px;*/
  left: 542px;
  top: 0px;
  width: 10px;
  height: 10px;
} #headerBotLeft {
  border: none; /*2px solid blue;*/
  position: absolute;
  left: 10px;
  top: var(--header-ht-top);
  width: 50%;
  height: 28px;
}
#headerTopMid { /* Message box - see #messageBox */
  border: none; /*1px solid red;*/
  position: relative;
  left: 570px;
  top: 0px;
  width: 390px;
  max-width: 500px;
  height: var(--header-msg-height);
  /*height: 32px;*/
} #headerTopRight {
  border: none; /*2px solid blue; */
  position: absolute;
  right: 0px;
  top: 0px;
  width: 35%;
  height: 30px;
} #headerBotRight {
  border: none; /*1px solid orange; 2px solid green;*/
  position: absolute;
  right: 0px;
  top: var(--header-ht-top);
  /*background-color: lightcoral;*/
  width: 40%;
  height: 28px;
  z-index: 0;
} #headerBotRight h2 {
  border: none; /*1px solid orange;  */
  margin-top: 2px;
  top: 0px;
  right: 450px; /* right from headerBotRight edge */
  font-size: 24px;
  color: black;
  font-weight: normal;
  float: left;
} #headerFunction {
  border: none; /*1px solid orange; /* */
  position: absolute;
  z-index: 2000;
  right: 0px;
  top: -26px; /* accomodate floating label */
  width: 210px;
  height: 48px;
  color: black;
  background-color: white;
} #headerBotStrip {
  border: none; /*1px solid red; */
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 100%;
  height: 13px;
  background: url(images/strip_bg.jpg) top left repeat;
} #dateDiv {
  border: none; /*1px solid blue; */
  position: absolute;
  right: 320px;
  top: 5px;
  width: 200px;
  height: 20px;
  font-size: 18px;
  color: black;
  font-weight: normal;
} #clockDiv {
  border: none; /*1px solid blue; */
  position: absolute;
  right: 530px;
  top: 5px;
  width: 44px;
  height: 20px;
  font-size: 18px;
  color: black;
  font-weight: normal;
} #loginDiv {
  border: none;
  position: absolute;
  right: 10px;
  top: 2px;
  width: 130px;
  height: 20px;
} #avatarDiv {
  border: none; /*1px solid green; */
  position: absolute;
  right: 10px;
  top: 0px;
  width: 150px;
  height: 40px;
} #statusDivLeft {
  border: none; /* border: 1px solid red; */
  position: absolute;
  left: 1px;
  top: 2px;
  width: 12px;
  height: 12px;
} #statusDivMid { /* Status Light */
  border: none; /*1px solid red;*/
  position: absolute;
  left: 515px;
  top: 2px;
  width: 12px;
  height: 12px;
} .statusImage {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 12px;
  resize: both;
  height: 12px;
} #functionDiv {
  border: none; /*1px solid red;*/
  position: absolute;
  left: 360px;
  bottom: 9px;
  width: 210px;
  height: 25px;
  z-index: 3000;
} #headerSearch {
  border: none; /*1px solid red; /* */
  position: relative;
  right: 0px;
  top: -26px; /* accomodate floating label */
  left: 60px;
  width: 210px;
  height: 48px;
  color: black;
  background-color: white;
} #searchBtnDiv { /* search button clear */
  border: none;
  position: absolute;
  left: 0px;
  bottom: 9px;
  width: 10px;
  height: 15px;
}
#searchBoxDiv { /* search button clear */
  /*border: 1px solid red;  */
  position: absolute;
  left: 32px;
} #clearSearchBtnDiv { /* search button clear */
  border: none;
  position: absolute;
  left: 235px;
  bottom: 9px;
  width: 10px;
  height: 15px;
}
  /*------------- Logon and Register css ---------------------------------------------------------------------------*/
  .pageLoginBorder {
  border: 2px solid blue;
  border-radius: 5px;
  position: absolute;
  display: block;
  left: 5%; /*calc((var(--screen-width) - 350px)/2px);*/
  height: 500px;
  max-width: 350px;
  min-width: 350px;
  background-color: white;
} .pageRegisterBorder {
  border: 2px solid blue;
  border-radius: 5px;
  position: absolute;
  display: block;
  left: 5%; /*calc((var(--screen-width) - 350px)/2px);*/
  height: 750px;
  max-width: 350px;
  min-width: 350px;
  background-color: white;
} .logLabel {
  font-size: large;
  color: black;
  font-weight: bold;
} .loginLabel {
  font-size: large;
  color: red;
  font-weight: bold;
} .signinField {
  font-size: medium;
  color: black;
  width: 280px;
  border-radius: 5px;
  border: 3px solid #ccc;
  margin: 2px 2px 2px 0px;
  padding: 2px 2px 2px 2px;
} .pwField {
  /* border: 2px solid red; */
  padding: 0;
  margin: 8px 3px 1px 20px;
  width: 100%;
  /*background-color: white;*/
  justify-content: right;
  height: 35px;
} #headerLogo {
  border: none;
  position: relative;
  left: 10px;
  top: 0px;
  display: block;
  width: 100%;
  height: 70px;
  /*background: url(images/LogoOllysGrey.jpg) top left no-repeat;*/
  background: url(images/ScanPayLogo.png) top left no-repeat;
  background-size: 130px 70px; /* NOTE - Logo Size */
} .registerLabel {
  font-size: medium;
  padding: 0 0 0 25px;
  color: black;
  font-weight: bold;
} .submitButton {
  border: none;
  border-radius: 5px;
  position: relative;
  left: 20px;
  top: 0px;
  display: block;
  width: 80%;
  height: 30px;
  color: white;
  font-size: 16px;
  background-color: blue;
} h2 {
  font-size: 24px;
  margin: 10px 0;
  padding: 0 0 0 20px;
  color: blue;
}
  /* old */
  #logonName {
  border: none; /*1px solid red;*/
  position: absolute;
  right: 159px;
  top: 5px;
  width: 200px;
  height: 20px;
  color: darkblue;
  font-family: Arial;
  font-size: 18px;
  text-align: right;
} .loginImage {
  position: relative;
  padding-left: 6px;
  bottom: 0px;
  width: 30px;
  resize: both;
  height: 20px;
} .loginImageSB {
  position: absolute;
  right: 60px;
  top: 3px;
  width: 26px;
  resize: both;
  height: 26px;
} .loginImageAvatar {
  position: absolute;
  right: 12px;
  top: 0px;
  width: 40px;
  height: 40px;
} .loginImageBig {
  position: relative;
  padding-right: 12px;
  top: 0px;
  width: 40px;
  resize: both;
  height: 40px;
} .headImage {
  position: relative;
  padding-left: -40px;
  top: 43px;
  width: 80px;
  resize: both;
} .topicImage {
  position: relative;
  padding-left: 0px;
  top: 0px;
  width: 60px;
  resize: both;
} .functionImage {  /* determines size of main icons */
  padding-left: 8px;
  top: 0px;
  width: 30px;
  height: 30px; 
  resize: both;
}
  /*------------- MENU -------------*/
  #menuCntr {
  position: absolute;
  width: 570px;
  height: 28px;
  bottom: 0;
  right: 0px;
} #menuCntr ul {
    margin: 0px;
    padding: 0;
  } #menuCntr li {
    padding-left: 1px;
    list-style: none;
    float: left;
  } #menuCntr li a {
      text-decoration: none;
      text-align: center;
      width: 89px;
      height: 30px;
      display: block;
      color: #fff;
      line-height: 30px;
      font-family: Arial;
      font-size: 18px;
      background: url(images/menu_bg.jpg) left top no-repeat;
    } #menuCntr li a:hover {
    color: #333333;
  }
  /*------------ FOOTER MENU & FOOTER CONTAINER ------------*/
  #footerCntr {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40px;
  overflow: hidden;
  background: url(images/welcome_bg.jpg) center top repeat;
} #footerCollapsed {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0px;
  overflow: hidden;
} #footTopStrip {
  border: none;
  position: absolute;
  left: 0;
  top: -1px;
  width: 100%;
  height: 5px;
  background: url(images/strip_bg.jpg) top left repeat;
} .footermenu {
  position: absolute;
  left: 2px;
  bottom: 10px;
  width: 520px;
  overflow: hidden;
} .footermenu ul li {
    padding: 0px 18px;
    list-style: none;
    float: left;
    background: url(images/footer_line.jpg) left 4px no-repeat;
  } .footermenu ul li a {
    color: #fff;
    font-size: 12px;
    text-decoration: none;
  } .footermenu ul li a:hover {
    text-decoration: underline;
  } .footermenu ul li.first {
    padding-left: 0px;
    background: none;
  } .footermenu p {
    padding-top: 14px;
    float: left;
    color: #fff;
    font-size: 12px;
  } .footermenu p a {
    color: #fff;
    text-decoration: none;
  } .footermenu p a:hover {
    text-decoration: underline;
  } #footerRight {
  border: none;
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 35%;
  height: 35px;
} .twitterbox {
  border: none;
  position: absolute;
  right: 10px;
  bottom: 7px;
  width: 110px;
  height: 21px;
} .linkbox {
  border: none;
  position: absolute;
  right: 200px;
  bottom: 7px;
  width: 150px;
  height: 21px;
}
  /* Help About */
  .aboutList {
  border: none; /*solid 3px green; */
  position: absolute;
  top: 10px; /* 110px;*/
  left: 0px;
  right: 0px;
  background-color: khaki;
}
  /* Help Menu */
  .menuList {
  border: none; /*solid 3px green; */
  position: absolute;
  top: 10px; /* 110px;*/
  left: 0px;
  width: 500px;
  background-color: khaki;
} .listHelpHeader {
  border-radius: 10px;
  border: 3px solid var(--button-back); /**/
  background-color: var(--ListHeadBGColor);
  height: 47px;
  padding: 0px 5px 0px 3px;
  font-size: large;
  color: white;
  overflow-x: hidden;
} .listHelpTitle {
  font-size: 22px;
  font-weight: bold;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
} .listHelpItemTitle {
  font-size: 18px;
  font-weight: bold;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
} .listHelpRow {
  overflow: auto;
  clear: both;
  border-radius: 10px;
  border: 2px solid var(--button-back);
  margin: 3px 0px 3px 3px;
  padding: 0px 0px 0px 3px;
  min-height: 84px;
} .listHelpFooter {
  border-radius: 10px;
  border: 3px solid var(--button-back); /**/
  background-color: var(--ListHeadBGColor);
  height: 48px;
  padding: 0px 5px 0px 3px;
  font-size: large;
  color: white;
  overflow-x: hidden;
} .editTabWholeLow {
  border: solid 3px green; /**/
  position: absolute;
  top: 30px; /* 110px;*/
  margin: 30px 3px 10px 3px;
  left: 0px;
  width: 90%;
  background-color: khaki;
} .editTabWholeLowSpread {
  border: solid 3px blue; /**/
  position: absolute;
  top: 70px; /* 110px;*/
  margin: 30px 3px 10px 3px;
  left: 0px;
  width: 90%;
  background-color: khaki;
} .listHeaderLeft {
  border: none;
  position: relative;
  background-color: var(--ListHeadBGColor);
  height: 40px;
  width: 75%;
  font-size: medium;
  color: black;
  float: left;
  top: 1px;
} .listHeaderRight {
  border: none; /*2px solid red;*/
  position: relative;
  background-color: var(--ListHeadBGColor);
  height: 40px;
  width: 25%;
  font-size: large;
  color: black;
  float: right;
  top: 2px;
} .listColA {
  float: left;
  width: calc(100% - 5px);
} .popupAboutDetail {
  width: 350px;
  height: 900px;
  border-radius: 15px;
  border: 3px solid blue;
  background: white;
  margin: 2px;
  padding: 3px 3px;
} .helpInfo {
  width: 100%;
  height: 100%;
  left: 0px;
  right: 0px;
  background: white;
  /*margin: 2px;
  border: 3px solid red;*/
} .helpDetail {
  width: 99%;
  height: 99%;
  margin: 2px 2px 2px 2px;
  left: 0px;
  right: 0px;
  background: white;
  /*margin: 2px;
  border: 3px solid red;*/
}
  /**/
  .popup-content { /* See messageBoxPU for content */
  padding: 3px 3px 3px 3px;
  color: #787878;
  background-color: #fcf7f8;
  border: 2px solid black;
  border-radius: 10px;
  height: 200px;
  width: 500px;
} .popupDetail { /* See detailPopup for content */
  padding: 2px 2px 2px 2px;
  color: #787878;
  background-color: #fcf7f8;
  border: 2px solid black;
  border-radius: 10px;
  height: 470px;
  width: 500px;
} .popupOptions { /* See optionPopup for content */
  padding: 2px 2px 2px 2px;
  color: #787878;
  background-color: #fcf7f8;
  border: 2px solid black;
  border-radius: 10px;
  height: var(--option-height);
  width: 500px;
} .popup-notifycontent { /* See notifyBoxPU for content*/
  padding: 3px 3px 3px 3px;
  color: #787878;
  background-color: #fcf7f8;
  border: 3px solid black;
  border-radius: 10px;
  height: 52px;
  width: 450px;
} .createPopUP {
  margin: 20px 10px 6px 15px;
}
  /*------------ SIDE CONTAINER ------------*/
  #sideContainer {
  position: absolute;
  bottom: 0;
  top: 0; /*var(--header-height); */
  width: var(--main-left);
  height: 100%;
  overflow: hidden;
  background-color: white;
} .sideMenu {
  position: relative;
  top: 30px;
  bottom: 100px;
  color: #1B2026;
  font-weight: bold;
  background-color: white;
  overflow-y: hidden;
} .sideMenuMain {
  font-size: 16px;
  color: black;
} .sideMenuSub {
  font-size: 14px;
} .sideLogout {
  position: absolute;
  bottom: 30px;
  left: 30px;
  color: #1B2026;
  font-weight: bold;
  background-color: #1B2026;
} .sideLogoutLabel {
  position: absolute;
  bottom: 20px;
  left: 0px;
  width: 90px;
  color: #1B2026;
  font-weight: bold;
  background-color: white;  /*#1B2026;*/
} .logoutImage {
  position: absolute;
  bottom: 20px;
  left: 95px;
  width: 22px;
  height: 22px;
}
  /*------------- End of - Header and Footer css ---------------------------------------------------------------------------*/
  /*------------- Tile -------------*/
  #tileFrag {
  border: none; /*solid 4px green;*/
  left: 25px;
} #tileHeader {
  font-size: 18px;
  color: var(--text-color);
} #tileTime {
  font-size: 18px;
  color: red;
  font-weight: bolder;
} #tileButton {
  font-size: 16px;
  color: darkblue;
} .tileButton {
  font-size: 16px;
  color: darkblue;
  height: 24px;
} .tileName {
  font-size: 16px;
  color: var(--text-color);
} .tileHeadLabel {
  font-size: 18px;
  color: var(--text-color);
} #tileItems {
  font-size: 16px;
  color: black;
}
  /*------------- Edit Page css ---------------------------------------------------------------------------*/
  #editTop {
  border: none; /*solid 4px green;*/
  position: absolute;
  left: 0px;
  top: 3px;
  width: 50%;
  height: 45%;
  background-color: red;
}
  /* See editTabLeft */
  #editTopLeft {
  border: none; /*solid 4px green; */
  position: absolute;
  left: 0px;
  top: 3px;
  width: 35%;
  height: 45%;
  background-color: white;
} .editTabLeftLow {
  border: none; /*solid 4px red; */
  position: absolute;
  left: 0px;
  top: 38px; /* Room for tab*/
  bottom: 1px;
  width: 50%;
  background-color: white;
} .editTabWholeLow {
  border: none; /*solid 4px red; */
  position: absolute;
  left: 0px;
  top: 15px; /* Room for tab*/
  bottom: 1px;
  width: 98%;
  background-color: white;
} #editTopRight {
  border: none; /*solid 4px blue; */
  position: absolute;
  right: 0px;
  top: 3px;
  width: 64%;
  height: 45%;
} #editBottom {
  border: none;
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 53%;
} .editInside {
  border: none; /*solid 3px red;*/
  position: absolute;
  top: 2px;
  bottom: 1px;
  left: 10px;
  right: 10px;
  background-color: white;
} .editInsideTab {
  border: none; /*solid 3px black; */
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: white;
} .editInsideSub1 {
  border: none; /*solid 1px red; */
  position: absolute;
  top: 40px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: white;
} .editInsideSub2 {
  border: none; /*solid 1px red; */
  position: absolute;
  top: 90px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: white;
} .bottomButton {
  border: none; /*solid 1px red; */
  position: absolute;
  bottom: 15px;
  left: 20px;
  background-color: white;
} .bottomEdit {
  border: none; /*solid 1px red; */
  position: absolute;
  bottom: 40px;
  right: 10px;
  width: 150px;
  background-color: white;
} .headerDiv {
  border: none; /*solid 4px red; */
  position: absolute;
  top: 5px;
  bottom: 1px;
  left: 0px;
  background-color: white;
} .headerTitle {
  border: none; /*solid 3px black; */
  position: absolute;
  top: -2px;
  left: 5px;
  right: 5px; 
  width: 200px;
  background-color: white;
} .headerTitleWide {
  border: none; /*solid 3px black; */
  position: absolute;
  top: -2px;
  left: 5px;
  right: 5px;
  width: 600px;
  background-color: white;
} .headerGrid {
  border: none; /*solid 3px black; */
  position: absolute;
  top: 50px;
  bottom: 1px;
  background-color: white;
} .contactEdit {
  /* border: solid 3px blue; */
  top: 0; /* 110px;*/
  margin-left: 5px;
  left: 15px;
  right: 5px;
  width: 96%;
  height: 60%;
  background-color: white;
} .contactBox {
  /*border: solid 3px blue;*/
  font-size: 16px;
  font-weight: bold;
  color: black;
  margin: 0;
  width: 90%;
  height: 100%;
  overflow: hidden;
}
  /*-----------------------------------------------------------*/
  .buttonLeft {
  border: none; /*2px solid green; */
  position: absolute;
  left: 20px;
  width: 150px;
  height: 28px;
} .buttonMiddle {
  border: none; /*2px solid red; */
  position: absolute;
  width: 150px;
  height: 28px;
  left: 180px;
} .buttonRight {
  border: none; /*2px solid red; */
  position: absolute;
  width: 330px;
  height: 28px;
  left: 180px;
} .buttonRRight {
  border: none; /*2px solid red; */
  position: absolute;
  width: 330px;
  height: 28px;
  left: 90%;
} .tabStrip {
  height: 100%;
  width: 100%;
} .editTab {
  /*border: solid 4px blue; */
  position: absolute;
  top: 5px; /* Room for tab*/
  left: 5px;
  bottom: 0;
  right: 0;
  background-color: white;
} .editTabHelp {
  position: absolute;
  top: 5px; /* Room for tab*/
  left: 5px;
  bottom: 0;
  right: 0;
  background-color: white;
} .editPane {  /* Not used with tabs */
  /*border: solid 4px red;  */
  position: absolute;
  left: 0px;
  top: 4px; 
  bottom: 1px;
  left: 5px;
  right: 5px;
  bottom: 0;
  right: 0;
  background-color: white;
} .editTabTop {
  border: none; /*solid 4px green; */
  position: absolute;
  left: 0px;
  top: 1px;
  width: 100%;
  height: 45%;
  background-color: white;
} .editTabBottom {
  border: none;
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 50%;
} .editTabLeft {
  border: none; /*solid 1px blue; */
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 1px;
  width: 50%;
  background-color: white;
} .editTabLeftNarrow {
  /*border: solid 1px blue; */
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 1px;
  width: 33%;
  background-color: white;
} .editTabLeftExNarrow {
  /*border: solid 1px blue; */
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 1px;
  width: 20%;
  background-color: white;
} .editTabLeftNarrowTop {
  /*border:solid 1px blue; */
  position: absolute;
  left: 2%;
  top: 1px;
  bottom: 50%;
  width: 47%;
  background-color: white;
} .editTabLeftNarrowTopR1 {
  /*border:solid 1px blue; */
  position: absolute;
  left: 290px;
  top: 1px;
  width: 290px;
  background-color: white;
} .editTabLeftNarrowTopR2 {
  /*border:solid 1px blue; */
  position: absolute;
  left: 520px;
  top: 1px;
  width: 230px;
  background-color: white;
} .editTabLeftNarrowTopR3 {
  /*border:solid 1px blue; */
  position: absolute;
  left: 800px;
  top: 1px;
  width: 280px;
  background-color: white;
} .editTabLeftNarrowBot {
  /*border:solid 1px blue; */
  position: absolute;
  left: 2%;
  top: 50%;
  bottom: 1px;
  width: 47%;
  background-color: white;
} .editTabRightNarrowTop {
  /*border:solid 1px blue; */
  position: absolute;
  right: 2%;
  top: 1px;
  bottom: 50%;
  width: 47%;
  background-color: white;
} .editTabRightNarrowBot {
  /*border:solid 1px blue; */
  position: absolute;
  right: 2%;
  top: 50%;
  bottom: 1px;
  width: 47%;
  background-color: white;
} .editTabNarrowBot {
  /*border:solid 1px blue; */
  position: absolute;
  right: 2%;
  top: 50%;
  bottom: 1px;
  width: 47%;
  background-color: white;
} .graphTabNarrowTop {
  /*border:solid 1px blue; */
  position: absolute;
  left: 2%;
  top: 1px;
  bottom: 50%;
  height: 48%;
  width: 90%;
  background-color: white;
} .graphTabNarrowBot {
  /*border:solid 1px blue; */
  position: absolute;
  left: 20%;
  top: 50%;
  bottom: 1px;
  width: 50%;
  background-color: white;
} .graphTabNarrowRightTop {
  /*border:solid 1px blue; */
  position: absolute;
  right: 2%;
  top: 1px;
  bottom: 60%;
  height: 40%;
  width: 47%;
  background-color: white;
} .graphTabNarrowRightBot {
  /*border:solid 1px blue; */
  position: absolute;
  right: 2%;
  top: 50%;
  bottom: 1px;
  width: 47%;
  background-color: white;
} .graphTabNarrowLeftTop {
  /*border:solid 1px blue; */
  position: absolute;
  left: 2%;
  top: 1px;
  bottom: 60%;
  height: 40%;
  width: 47%;
  background-color: white;
} .graphTabNarrowLeftBot {
  /*border:solid 1px blue; */
  position: absolute;
  left: 2%;
  top: 50%;
  bottom: 1px;
  width: 47%;
  background-color: white;
} .editTabLeftWide {
  border: none; /*solid 1px blue; */
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 1px;
  width: 67%;
  background-color: white;
} .editTabMiddle {
  border: none; /*solid 1px green;  */
  position: absolute;
  left: 33%;
  top: 1px;
  bottom: 1px;
  width: 34%;
  background-color: white;
} .editTabMiddleLeft {
  /*border: solid 1px green;  */
  position: absolute;
  left: 20%;
  top: 1px;
  bottom: 1px;
  width: 15%;
  background-color: white;
} .editTabMiddleRight {
  /*border: solid 1px red;  */
  position: absolute;
  left: 35%;
  top: 1px;
  bottom: 1px;
  width: 30%;
  background-color: white;
} editTabMiddleWhole {
  /*border:solid 1px blue; */
  position: absolute;
  left: 5%;
  top: 5%;
  height: 90%;
  bottom: 1px;
  width: 90%;
  background-color: white;
} .editTabLeftFixed {
  border: none; /*solid 1px blue; */
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 1px;
  width: 970px;
  background-color: white;
} .editTabLeftFixedNarrow {
  border: none; /*solid 1px blue; */
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 1px;
  width: 420px;
  background-color: white;
} .editTabRight {
  border: none; /*solid 1px green;  */
  position: absolute;
  right: 1px;
  top: 0px;
  bottom: 0px;
  width: 50%;
  background-color: white;
} .editTabRightFixSpc {
  border: none; /*solid 1px green;  */
  position: absolute;
  left: 1000px;
  top: 0px;
  bottom: 0px;
  width: 950px;
  background-color: white;
} .editTabRightNarrow {
  /*border: solid 1px green;  */
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 33%;
  min-width: 250px;
  background-color: white;
}.editTabRightXNarrow {
  /*border: solid 1px green;  */
  position: absolute;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 23%;
  min-width: 250px;
  background-color: white;
} .editTabMidRightNarrow {
  border: none; /*solid 1px green;  */
  position: absolute;
  left: 900px;
  top: 0px;
  bottom: 0px;
  background-color: white;
}
  /*-------- Edit Fields -------------------*/
  .displayField {
  border: none; /*1px solid green; */
  margin: 1px 3px 1px 3px;
  width: 99%;
  /*background-color: white;*/
  height: 26px;
} .displayInput {
  border: none;
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 65%;
  max-width: 340px;
  height: 20px;
  margin-top: 0px;
  font-size: 16px;
  color: var(--text-color);
  padding-left: 0;
  background-color: none;
}
  /*-----*/
  .editDiv {
  position: relative;
  margin: 1px 3px 1px 3px;
  width: 99%;
} .editDivBlue {
  position: relative;
  border: 2px solid blue; /* */
  border-radius: 5px;
  padding: 3px 3px 1px 5px;
  width: 99%;
} .editField {
  margin: 1px 3px 1px 3px;
  width: 99%;
  height: 35px;
} .editFieldTA {
  border: 2px solid blue; /* */
  margin: 1px 3px 1px 3px;
  width: 99%;
  height: 35px;
} .editFieldShort {
  border: none; /*1px solid green; */
  margin: 1px 3px 1px 3px;
  width: 30%;
  /*background-color: white;*/
  height: 35px;
} .editFieldMedium {
  border: none; /*1px solid green; */
  margin: 1px 3px 1px 3px;
  width: 60%;
  /*background-color: white;*/
  height: 35px;
} .editTAField {
  margin: 1px 3px 1px 3px;
  width: 99%;
  height: 72px;
} .editTAXField {
  border: none; /*2px solid red; */
  margin: 1px 3px 1px 3px;
  width: 99%;
  /*background-color: white;*/
  height: 77px;
} .editTAHField {
  border: none; /*2px solid red; */
  margin: 1px 3px 1px 3px;
  width: 99%;
  height: 125px;
} .editPicField {
  border: none; /*2px solid red; */
  margin: 1px 3px 1px 3px;
  width: 99%;
  height: 445px;
} .editFieldLabel {
  border: none; /*solid 1px blue;*/ 
  font-size: small;
  left: 0;
  color: black;
} .editFieldLabelR {
  display: inline-block;
  font-size: 15px;
  width: 22%;
  padding-top: 4px;
  text-align: right;
  color: black;
} .editSwitch {
  border: solid 1px blue; /**/
  position: absolute;
  left: 80px;
} .editFieldLabel {
  border: none; /*solid 1px blue;*/ 
  font-size: small;
  left: 0;
  color: black;
}
  /*--------- Horizontal Fields ------------*/
  .editFieldHZ1 {
  /*border: 1px solid green;  */
  position: absolute;
  margin: 0;
  top: 4px;
  width: 350px;
  height: 32px;
} .editFieldLabelHZ1 {
  border: none; /*solid 1px blue;*/
  font-size: small;
  color: black;
} .editHZInput {
  border: 1px solid var(--box-color);
  border-radius: 3px;
  /*box-shadow: 0 0 0 2px var(--box-color);*/
  position: relative;
  width: 180px;
  height: 30px;
  left: 10px;
  padding-left: 3px;
  margin-top: 0px;
  height: 28px;
  color: var(--text-color);
  background-color: var(--back-color);
} .editHZInputWide {
  border: 1px solid var(--box-color);
  border-radius: 3px;
  /*box-shadow: 0 0 0 2px var(--box-color);*/
  position: relative;
  width: 285px;
  height: 30px;
  left: 5px;
  padding-left: 3px;
  margin-top: 0px;
  height: 28px;
  color: var(--text-color);
  background-color: var(--back-color);
} .editFieldHZ2 {
  /*border: 3px solid green;  */
  position: absolute;
  margin: 0;
  top: 4px;
  left: 365px;
  width: 260px;
  height: 32px;
} .editFieldHZ3 {
  /*border: 3px solid green;  */
  position: absolute;
  margin: 0;
  top: 4px;
  left: 635px;
  width: 260px;
  height: 32px;
} .editFieldHZ4 {
  /*border: 3px solid green;  */
  position: absolute;
  margin: 0;
  top: 7px;
  left: 915px;
  width: 260px;
  height: 32px;
} .editFieldHZ5 {
  /*border: 3px solid green;  */
  position: absolute;
  margin: 0;
  top: 4px;
  left: 1150px;
  width: 260px;
  height: 32px;
} .editDDPosHZ { /* div around Drop Down and Multiselect */
  position: absolute;
  left: 80px;
  margin-top: -23px;
  height: 28px;
  width: 150px;
}
  /*-------- Edit Fields -------------------*/
  .editInput {
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color);
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 65%;
  max-width: 340px;
  height: 23px;
  margin-top: 0px;
  color: var(--text-color);
  padding-left: 3px;
  background-color: var(--back-color);
}
.editInputRO {
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color);
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 65%;
  max-width: 340px;
  height: 23px;
  margin-top: 0px;  
  padding-left: 3px;
  color: var(--text-color);
  background-color: var(--back-color-ro);
}
.editInputWide {
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color);
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  height: 23px;
  width: 80%;
  margin-top: 0px;
  color: var(--text-color);
  padding-left: 3px;
  background-color: var(--back-color);
} .editInputRed {
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color-red);
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 65%;
  max-width: 340px;
  height: 23px;
  margin-top: 0px;
  color: var(--text-color);
  padding-left: 3px;
  background-color: var(--back-color);
} .editInputMedium {
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color);
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 40%;
  width: 140px;
  height: 23px;
  margin-top: 0px;
  color: var(--text-color);
  padding-left: 3px;
  background-color: var(--back-color);
} .editInputShort {
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color);
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 70px;
  height: 23px;
  margin-top: 0px;
  color: var(--text-color);
  padding-left: 3px;
  background-color: var(--back-color);
} .editNumInput {
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color);
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 110px;
  height: 23px;
  margin-top: 0px;
  text-align: right;
  color: var(--text-color);
  padding-right: 3px;
  background-color: var(--back-color);
} .editNumInputRO {
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color);
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 110px;
  height: 23px;
  margin-top: 0px;
  text-align: right;
  color: var(--text-color);
  padding-right: 3px;
  background-color: var(--back-color-ro);
} .editNumShortInput {
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color);
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 60px;
  height: 23px;
  margin-top: 0px;
  text-align: right;
  color: var(--text-color);
  padding-right: 3px;
  background-color: var(--back-color);
} .editNumShortInputRO {
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color);
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 60px;
  height: 23px;
  margin-top: 0px;
  text-align: right;
  color: var(--text-color);
  padding-right: 3px;
  background-color: var(--back-color-ro);
} .NumTxtXShortInput {
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color);
  position: relative;
  left: 89px;
  right: 40px;
  width: 40px;
  height: 23px;
  margin-top: 0px;
  text-align: right;
  color: var(--text-color);
  margin-right: 30px;
  background-color: var(--back-color);
} .NumTxtExtShortInput { /* Used for Numeric Text boxes - which have a width parameter*/
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color);
  position: absolute;
  left: var(--prompt-left);
  width: 85px;
  max-width: 85px;
  height: 23px;
  margin-top: 0px;
  text-align: right;
  color: var(--text-color);
  margin-right: 10px;
  background-color: var(--back-color);
} .NumTxtExtShortInputRO { /* Used for Numeric Text boxes - which have a width parameter*/
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color);
  position: absolute;
  left: var(--prompt-left);
  width: 85px;
  max-width: 85px;
  height: 23px;
  margin-top: 0px;
  text-align: right;
  color: var(--text-color);
  margin-right: 10px;
  background-color: var(--back-color-ro);
} .NumTxtShortInputLeft {
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color);
  position: relative;
  left: 0px;
  width: 40px;
  height: 23px;
  margin-top: 0px;
  text-align: right;
  color: var(--text-color);
  margin-right: 30px;
  background-color: var(--back-color);
} .editPhoneInput {
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color);
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 130px;
  max-width: 340px;
  height: 23px;
  margin-top: 0px;
  color: var(--text-color);
  padding-left: 3px;
  background-color: var(--back-color);
} .editMoneyInput {
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color);
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 110px;
  height: 23px;
  margin-top: 0px;
  text-align: right;
  color: var(--text-color);
  padding-right: 3px;
  background-color: var(--back-color);
} .editMoneyInputRed {
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color-red);
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 110px;
  height: 23px;
  margin-top: 0px;
  text-align: right;
  color: var(--text-color);
  padding-right: 3px;
  background-color: var(--back-color);
} .editMoneyInputRO {
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color);
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 110px;
  height: 23px;
  margin-top: 0px;
  text-align: right;
  color: var(--text-color);
  padding-right: 3px;
  background-color: var(--back-color-ro);
} .editMoneyShortInput {
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color);
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 50px;
  height: 23px;
  margin-top: 0px;
  text-align: right;
  color: var(--text-color);
  padding-right: 3px;
  background-color: var(--back-color);
}
  /*----------- Branding Samples --------------*/
.InputTextSampleCSS {
  border: solid;
  border-width: 3px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
  position: absolute;
  left: 75px;
  width: 85%;
  max-width: 400px;      
  height: 30px;
  color: var(--InputTextColor);
  background-color: var(--InputBGColor);
  font-family: var(--InputFontType);
  font-size: var(--InputFontSize);
}
.ButtonSampleCSS {
  border: solid;
  border-width: 3px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
  position: absolute;
  left: 75px;
  width: 85%;
  max-width: 340px;     
  height: 30px;
  color: var(--ButtonTextColor);
  background-color: var(--ButtonBGColor);
  font-family: var(--ButtonFontType);
  font-size: var(--ButtonFontSize);
}
.HeadingSampleCSS {
  border: solid;
  border-width: 3px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
  position: absolute;
  left: 75px;
  width: 85%;
  max-width: 340px;          
  height: 30px;
  color: var(--HeadingTextColor);
  background-color: var(--InputBGColor);
  font-family: var(--HeadingFontType);
  font-size: var(--HeadingFontSize);
}
.ItemNameSampleCSS {
  border: solid;
  border-width: 3px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
  position: absolute;
  left: 75px;
  width: 85%;
  max-width: 340px;          
  height: 30px;
  color: var(--ItemNameColor);
  background-color: var(--ListBGColor);
  font-family: var(--ItemNameFontType);
  font-size: var(--ItemNameFontSize);
  font-weight: var(--ItemNameFontWht);
}
.ItemDescSampleCSS {
  border: solid;
  border-width: 3px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
  position: absolute;
  left: 75px;
  width: 85%;
  max-width: 340px;        
  height: 30px;
  color: var(--ItemDescColor);
  background-color: var(--ListBGColor);
  font-family: var(--ItemDescFontType);
  font-size: var(--ItemDescFontSize);
  font-weight: var(--ItemDescFontWht);
}
.ListSampleCSS {
  border: solid;
  border-width: 3px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
  position: absolute;
  left: 75px;
  width: 85%;
  max-width: 340px;      
  height: 30px;
  color: var(--TextColor);
  background-color: var(--ListBGColor);
  font-family: var(--ListFontType);
  font-size: var(--ListFontSize);
}
.HeadSampleCSS {
  border: solid;
  border-width: 3px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
  position: absolute;
  left: 75px;
  width: 85%;
  max-width: 340px;          
  height: 30px;  
  color: var(--ListHeadTextColor);
  background-color: var(--ListHeadBGColor);
  font-family: var(--HeadFontType);
  font-size: var(--HeadFontSize);
}
.SectionSampleCSS {
  border: solid;
  border-width: 3px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
  position: absolute;
  left: 75px;
  width: 85%;
  max-width: 340px;    
  height: 30px;
  color: var(--SectionTextColor);
  background-color: var(--ListSectionBGColor);
  font-family: var(--SectionFontType);
  font-size: var(--SectionFontSize);
}
.sampleInput8 {
  border: solid;
  border-width: 3px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
  position: absolute;
  left: 75px;
  width: 85%;
  max-width: 340px;         
  height: 30px;
  font-family: var(--SectionFontType);
  font-size: var(--SectionFontSize);
  color: var(--HeadingTextColor);
  background-color: var(--ListSectionBGColor);
}
.sampleInput9 {
  border: solid;
  border-width: 3px;
  border-color: var(--InputBorderColor);
  border-radius: 3px;
  position: absolute;
  left: 75px;
  width: 85%;
  max-width: 340px;           
  height: 30px;
  font-family: var(--PromptFontType);
  font-size: var(--PromptFontSize);
  color: var(--PromptColor);
  background-color: var(--InputBGColor);
}
/*-------- Div CSS --------*/
.clearFix {
  overflow: auto;
  /*this will make the image adjust within the div tag*/
}
.editDDPos { /* div around Drop Down and multiselect */
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  margin-top: -23px;
  height: 28px;
  width: 65%;
}
.editDDPosL { /* div around Drop Down and multiselect */
  position: absolute;
  left: 55px;
  margin-top: -23px;
  height: 28px;
  width: 175px;
}
.editDDPosM { /* div around Drop Down and multiselect */
  position: absolute;
  left: 200px;
  margin-top: -23px;
  height: 28px;
  width: 125px;
}
.editDDPosR { /* div around Drop Down and multiselect */
  position: absolute;
  left: 300px;
  margin-top: -23px;
  height: 28px;
  width: 125px;
}
/*  overflow: auto;*/
.editInputDD { /* Inside editDDPos */
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color);
  position: absolute;
  left: 0px;
  width: 90%;
  max-width: 240px;
  margin-top: 0px;
  color: var(--text-color);
  padding-left: 0px;
  background-color: var(--back-color);
} .editInputDDSh { /* Inside editDDPos */
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color);
  position: absolute;
  left: 0px;
  width: 70%;
  max-width: 120px;
  margin-top: 0px;
  color: var(--text-color);
  padding-left: 0px;
  background-color: var(--back-color);
} .editInputDDRed { /* Inside editDDPos */
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color-red);
  position: absolute;
  left: 0px;
  width: 90%;
  max-width: 240px;
  margin-top: 0px;
  color: var(--text-color);
  padding-left: 0px;
  background-color: var(--back-color);
}
.editInputDDShort { /* Inside editDDPos */
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color);
  position: absolute;
  left: 20px;
  width: 170px;
  margin-top: 0px;
  color: var(--text-color);
  padding-left: 0px;
  background-color: var(--back-color);
}
.editInputDVShort { /* Inside editDDPos */
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color);
  position: absolute;
  left: 20px;
  width: 120px;
  margin-top: 0px;
  color: var(--text-color);
  padding-left: 0px;
  background-color: var(--back-color);
}
.editInputDVExShort { /* Inside editDDPos */
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color);
  position: absolute;
  left: 20px;
  width: 80px;
  margin-top: 0px;
  color: var(--text-color);
  padding-left: 0px;
  background-color: var(--back-color);
}
  .editDateInput {
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color);
  position: absolute;
  left: 0px;
  width: 240px;
  margin-top: 0px;
  color: var(--text-color);
  padding-left: 0px;
  background-color: var(--back-color);
} .editDateInputShort {
  border: none;
  position: absolute;
  left: 0px;
  width: 180px;
  margin-top: 0px;
  color: var(--text-color);
  padding-left: 0px;
  background-color: var(--back-color);
} .chkField { /* div around field Selection Check*/
  position: absolute;
  left: var(--prompt-left);
  right: 4px;
  width: 40px;
  height: 23px;
}
  /*----- Textarea CSS -------*/
  .editTAInput {
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color);
  position: absolute;
  left: var(--prompt-left);
  height: 60px;
  right: 4px;
  width: 66%;
  word-wrap: break-word;
  padding: 2px 3px 2px 3px;
  margin-top: 0px;
  color: var(--text-color);
  font-size: large;
  resize: none;
  background-color: var(--back-color);
} .editTAInputRed {
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color-red);
  position: absolute;
  left: var(--prompt-left);
  height: 60px;
  right: 4px;
  width: 66%;
  word-wrap: break-word;
  padding: 2px 3px 2px 3px;
  margin-top: 0px;
  color: var(--text-color);
  font-size: large;
  resize: none;
  background-color: var(--back-color);
} .editTAInputHigh {
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color);
  position: absolute;
  left: var(--prompt-left);
  height: 120px;
  right: 4px;
  width: 66%;
  word-wrap: break-word;
  padding: 2px 3px 2px 3px;
  margin-top: 0px;
  color: var(--text-color);
  font-size: large;
  resize: none;
  background-color: var(--back-color);
} .editTAInputLong { /* Note - currently the same as InputHigh */
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color);
  position: absolute;
  left: var(--prompt-left);
  height: 120px;
  right: 4px;
  width: 66%;
  word-wrap: break-word;
  padding: 2px 3px 2px 3px;
  margin-top: 0px;
  color: var(--text-color);
  font-size: large;
  resize: none;
  background-color: var(--back-color);
} .editTAInputWide {
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color);
  position: absolute;
  left: var(--prompt-left);
  height: 60px;
  right: 4px;
  width: 80%;
  word-wrap: break-word;
  vertical-align: text-top;
  margin-top: 0px;
  color: var(--text-color);
  font-size: large;
  resize: none;
  padding: 2px 3px 2px 3px;
  background-color: var(--back-color);
} .editTAHInputWide {
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color);
  position: absolute;
  left: var(--prompt-left);
  height: 120px;
  right: 4px;
  width: 80%;
  word-wrap: break-word;
  vertical-align: text-top;
  margin-top: 0px;
  color: var(--text-color);
  font-size: large;
  resize: none;
  padding: 2px 3px 2px 3px;
  background-color: var(--back-color);
} .editTAInputFull {
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color);
  position: absolute;
  left: 4px;
  height: 80px;
  right: 4px;
  width: 96%;
  word-wrap: break-word;
  vertical-align: text-top;
  margin-top: 0px;
  color: var(--text-color);
  font-size: large;
  border: none;
  padding: 2px 3px 2px 3px;
  background-color: var(--back-color);
} .editTAInputFull1 {
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color);
  position: absolute;
  left: 4px;
  height: 80px;
  right: 4px;
  width: 94%;
  word-wrap: break-word;
  vertical-align: text-top;
  margin: 8px 3px 0px 9px;
  color: var(--text-color);
  font-size: large;
  border: none;
  padding: 2px 3px 2px 3px;
  background-color: var(--back-color);
} .editTAInputFullHigh {
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color);
  position: absolute;
  left: 11px;
  height: 160px;
  right: 10px;
  width: 95%;
  vertical-align: text-top;
  color: var(--text-color);
  font-size: large;
  resize: none;
  padding: 2px 3px 2px 3px;
  background-color: var(--back-color);
} .editTAColorInput {
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px darkgray; /*var(--box-color);*/
  position: relative;
  left: var(--prompt-left);
  height: 65px;
  width: 125px;
  top: -18px;
  word-wrap: break-word;
  padding-left: 7px;
  margin-top: 0px;
  color: var(--text-color);
  font-size: large;
  resize: none;
  /*  box-shadow: 0 0 0 2px var(--box-color);*/
  background-color: lightgray; /*var(--back-color); */
} .iFrame {                  
  border: solid 3px green; /**/
  border-radius: 5px;
}
  /*------------- Narrow --------------------------------------------*/
  .editNrwNumShortInput {
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 0 2px var(--box-color);
  position: absolute;
  left: var(--prompt-narrow-left);
  right: 4px;
  width: 50px;
  height: 23px;
  margin-top: 0px;
  color: var(--text-color);
  padding-left: 3px;
  background-color: var(--back-color);
} .editNrwDDPos { /* div around Drop Down and Date, DateTime*/
  position: absolute;
  left: var(--prompt-narrow-left);
  right: 4px;
  margin-top: -30px;
  height: 28px;
}
  /*------------- End of - Edit Page css ---------------------------------------------------------------------------*/
  .overlay {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  /*background-color: rgb(0,0,0);*/
  background-color: rgba(0,0,0, 0.9);
  overflow-x: hidden;
  transition: 0.5s;
}
  /*---------- Splitter --------------------*/
  .splitPaneContent {
  padding: 0 10px;
  height: 100%;
  /*border: solid 3px red;*/
} .pane-contentwide {
  padding: 2px 2px 2px 2px;
  margin: 1px 1px 1px 1px;
  width: 100%;
  height: 850px;
  border: none; /*solid 3px red; */
} .pane-contentwidefixed {
  padding: 10px 10px 10px 10px;
  width: 1200px;
  border: solid 3px green; /**/
} h3 {
  font-size: 1.2em;
  margin: 10px 0;
  padding: 0;
} p {
  margin: 0;
  padding: 0;
} #divC {
  position: absolute;
  border: none; /*solid 3px black; */
  top: 0px;
  margin: -10px 0px 10px 0px;
  height: 90%;
  width: 90%;
} #chartSingle {
  border: solid 3px red;
  position: absolute;
  top: 0px;
  bottom: 20px;
  left: 10px;
  right: 10px;
  background-color: red;
}
  /*---------- ListItems --------------------------------------------------------------------------------------------------------------------------------------------*/
  .listRow {
  overflow: auto;
  clear: both;
  border-radius: 10px;
  border: 2px solid #ccc;
  margin: 3px 3px 3px 3px;
  padding: 3px 3px 3px 3px;
} .listRowS {
  overflow: auto;
  clear: both;
  border: none;
  margin: 3px 3px 3px 3px;
  padding: 3px 3px 3px 3px;
} .topItems {
  width: 97%;
  height: 60px;
  overflow: auto;
  clear: both;
  /*border: 3px solid green; */
  margin: 3px 3px 3px 3px;
  padding: 3px 3px 3px 3px;
} .bottomItems {
  width: 97%;
  overflow: auto;
  clear: both;
  height: 60px;
  /*border: 3px solid red; */
  margin: 3px 3px 3px 3px;
  padding: 3px 3px 3px 3px;
} .listCol1 {
  float: left;
  width: 25%;
} .listCol2 {
  float: left;
  width: 20%;
} .listCol3 {
  float: left;
  width: 40%;
} .listCol4 {
  float: left;
  width: 15%;
} .listCol5 {
  float: left;
  width: 10%;
} .listCol6 {
  float: left;
  width: 20%;
  background-color: darkturquoise;
} .listFooter {
  border-radius: 10px;
  border: 3px solid #ccc;
  background-color: #6080DD;
  height: 65px;
  font-size: large;
  color: white;
  overflow-x: hidden;
  padding: 10px 1px 1px 15px;
} .footerLeft {
  float: left;
  width: 35%;
} .footerRight {
  float: left;
  width: 35%;
}
  /*---------- ENd of ListItems --------------------------------------------------------------------------------------------------------------------------------------------*/
  /*--------------------------------------------------------------------------------------------------------------------------------*/
  /* Template css - Get rid of what is not used specifically - March 8, 2019           */
  /*--------------------------------------------------------------------------------------------------------------------------------*/
  /* ### BANNER CONTAINER ### */
  #bannerCntr {
  position: relative;
  top: 0;
  float: none;
  margin-left: auto;
  margin-right: auto;
  width: 1000px;
  height: 366px;
  background: url(images/banner_bg.jpg) left top no-repeat;
} .bannerTextBox {
  padding-top: 104px;
  padding-left: 50px;
  float: left;
  width: 450px;
  overflow: hidden;
} .bannerTextBox p {
    font-family: Arial;
    color: #fff;
    font-size: 28px;
  }
  /* ### TEXT BOX ### */
  .textBox {
  padding-top: 124px;
  float: left;
  width: 475px;
  overflow: hidden;
}
  /* ### BANNER BOX ### */
  .bannerBox {
  padding: 50px 0px 0px 25px;
  float: left;
  width: 428px;
  overflow: hidden;
}
  /* ### HEADING BOX ### */
  .headingBox {
  width: 98%;
  overflow: hidden;
  height: 176px;
} .headingBox img {
    padding-top: 15px;
    float: right;
  }
  /* ### CONTENT CONTAINER ### */
  #contentCntr {
  overflow: hidden;
  width: 98%;
  margin: 0 auto;
}
  /*--------------------------------------------------------------------------------------------------------------------------------*/
  /* Original ContractAccess css            */
  /*--------------------------------------------------------------------------------------------------------------------------------*/
  .App {
  text-align: center;
} .App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 30vmin;
} .App-header {
  background-color: #282c34;
  min-height: 3vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
} .App-link {
  color: #61dafb;
} .Input-Half {
  width: 200px;
  color: var(--text-color);
} .Input-Full {
  width: 340px;
  color: var(--text-color);
} .ButtonLeft {
  text-align: left;
  width: 20px;
  height: 20px;
} .ButtonImg {
  text-align: center;
  margin-top: 3px;
  margin-left: 3px;
  background-color: #000000;
  border: 0px;
  width: 20px;
  height: 20px;
} .ButtonRight {
  text-align: right;
} .gridButton {
  cursor: pointer;
  color: var(--ButtonTextColor);
  background-color: var(--ButtonBGColor);
  font-size: small;
  font-weight: bold; 
  padding: 2px 4px 2px 4px;
  border: 1px solid var(--ButtonTextColor);
  border-radius: 3px;
} .smallButton {
  cursor: pointer;
  color: var(--ButtonTextColor);
  background-color: var(--ButtonBGColor);
  font-size: small;
  font-weight: bold;
  border-radius: 10px;
  border: 2px solid var(--ButtonTextColor);
} .medButton {
  cursor: pointer;
  color: var(--ButtonTextColor);
  background-color: var(--ButtonBGColor);
  font-size: medium;
  font-weight: bold;
  border-radius: 10px;
  border: 2px solid var(--ButtonTextColor);
} .medFxdButton {
  cursor: pointer;
  color: var(--ButtonTextColor);
  background-color: var(--ButtonBGColor);
  font-size: medium;
  font-weight: bold;
  width: 170px;
  border-radius: 10px;
  border: 2px solid var(--ButtonTextColor);
} .largeButton {
  cursor: pointer;
  color: var(--ButtonTextColor);
  background-color: var(--ButtonBGColor);
  font-size: large;
  font-weight: bold;
  border-radius: 10px;
  border: 2px solid var(--ButtonTextColor);
} .leftButton {
  cursor: pointer;
  color: var(--ButtonTextColor);
  background-color: var(--ButtonBGColor);
  font-size: small;
  font-weight: bold;
  border-radius: 10px;
  left: 5px;
  border: 2px solid var(--ButtonTextColor);
} .centerButton {
  cursor: pointer;
  color: var(--ButtonTextColor);
  background-color: var(--ButtonBGColor);
  font-size: small;
  font-weight: bold;
  border-radius: 10px;
  left: 20px;
  border: 2px solid var(--ButtonTextColor);
} .rightButton {
  cursor: pointer;
  color: var(--ButtonTextColor);
  background-color: var(--ButtonBGColor);
  font-size: small;
  font-weight: bold;
  border-radius: 10px;
  left: 40px;
  border: 2px solid var(--ButtonTextColor);
} .Header1 {
  text-align: center;
  color: #e0aa14;
  font-weight: bold;
  min-width: 200px;
} .Label1 {
  text-align: right;
  color: #e0aa14;
  font-weight: bold;
} .Label2 {
  text-align: center;
  color: #e0aa14;
  font-weight: bold;
} .Data1 {
  text-align: center;
  color: black;
  font-weight: bold;
} .Display1 {
  text-align: left;
  max-width: 180px; 
  color: var(--text-color);
} .Owner {
  text-align: right;
  color: #38b540;
  font-weight: bold;
}
/*------------------------------------------------------------------*/
.static {
  cursor: not-allowed;
} .draggable {
  cursor: move;
}  
  /*
.k-button {
  background-color: red
}
.k-button > .k-primary {
  color: red
}
.k-primary > .k-button > .k-grid-edit-command {
  background-color: red
}
.k-button > .k-grid-save-command {
  background-color: red
}
.k-button > .k-grid-cancel-command {
  background-color: red
}
.k-grid > td.k-state-selected, tr.k-state-selected > td {
  color: black;
  background-color: green;
} */
/*--------------------------------------------------------------------------------------------------------------------------------*/
/* override Telerik default css theme values                                                                                      */
/*--------------------------------------------------------------------------------------------------------------------------------*/
/*.k-header > a.k-link {
  color: #e0aa14;
  font-size: 16px
}
.k-button {
  background-color: red
}
.k-button > .k-primary {
  color: red
}
.k-primary > .k-button > .k-grid-edit-command {
  background-color: red
}
.k-button > .k-grid-save-command {
  background-color: red
}
.k-button > .k-grid-cancel-command {
  background-color: red
}
.k-grid > td.k-state-selected, tr.k-state-selected > td {
  color: black;
  background-color: green;
}
/* tabstrip width */
  /*.k-content .k-animation-container {
  width: 100%;
}
/* button image width */
  /*.k-button > .k-image {
  width: 20px;
  height: 20px;
} */
  /*--------------------------------------------------------------------------------------------------------------------------------*/
  /* end of telerik overrides */
  h3l {
  color: #3e7ae0;
  font-size: calc(24px);
  font-weight: bold;
}
  /*--------------------------------------------------------------------------------------------------------------------------------*/
  h3 {
  color: #3e7ae0;
  font-size: calc(18px);
} h4 {
  color: #3e7ae0;
  font-size: calc(16px);
} h5 {
  color: #eb0725;
  font-size: calc(10px + 1vmin);
} h6 {
  color: darkblue;
  font-size: calc(12px);
}
  /*td { Messes up Telerik Scheduler Calendar
  min-width: 70px;
}
*/
  @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
